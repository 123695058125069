import React from "react";
import styled, { keyframes } from "styled-components";

const DataProtection = () => {


  return (
    <Container>
      <div className="first-div">
        <div className="title-data">
          <h1>Datenschutzerklärung</h1>
        </div>
        <div className="second-part">
          <div className="h2-titles">
            <h2>1. Was tun wir?</h2>
            <p>
              PRI Facility Services GmbH (Werkstrasse 12, 5080 Laufenburg)
              betreibt die Website www.pri-fs.ch (nachfolgend als «wir»
              bezeichnet). Auf dieser Webseite können Sie sich über unsere
              Organisation informieren, unsere Online-Services in Anspruch
              nehmen und mit uns in Kontakt treten. Der Schutz Ihrer
              persönlichen Daten ist uns ein grosses Anliegen. Wir informieren
              Sie in dieser Datenschutzerklärung transparent und verständlich
              darüber, welche Daten wir über unsere Website sammeln und wie wir
              damit umgehen. Aus diesem Grund verwenden wir die Icons des
              Vereins PRIVACY ICONS. Sie sollen Ihnen dabei helfen, sich rasch
              einen Überblick über die Bearbeitung Ihrer Daten durch uns zu
              verschaffen.
            </p>
            <div className="h2-title">
              <h2>2. Über was informieren wir?</h2>
              <p>
                Welche Daten auf www.pri-fs.ch gesammeltwerden; Unter welchen
                Voraussetzungen wir Ihre Daten bearbeiten und zu welchem Zweck;
                Wie lange wir Ihre Daten aufbewahren; Wann eine Weitergabe an
                Dritte zulässig ist; Wie wir Ihre Daten bearbeiten («einzelne
                Datenbearbeitungsvorgänge»); Wann und wie Sie einer
                Datenbearbeitung widersprechen können; Welche Rechte Sie haben
                und wie Sie diese geltend machen können. 3. Begriffsdefinitionen
                3.1 Was sind personenbezogene Daten? Personenbezogene Daten sind
                alle Angaben, die sich auf eine bestimmte oder bestimmbare
                natürliche Person beziehen. Dazu gehören beispielsweise Name,
                Adresse, Geburtsdatum, E-Mail-Adresse oder Telefonnummer. Auch
                Daten über persönliche Vorlieben wie Freizeitbeschäftigungen
                oder Mitgliedschaften zählen zu den personenbezogenen Daten. 3.2
                Was sind besonders schützenswerte Personendaten? Daten über
                religiöse, weltanschauliche, politische oder gewerkschaftliche
                Ansichten oder Tätigkeiten; Daten über die Gesundheit und
                gegebenenfalls Angaben zu administrativen oder strafrechtlichen
                Verfolgungen und Sanktionen sowie Daten über Massnahmen der
                sozialen Hilfe gelten als besonders schützenswerte
                Personendaten. Soweit erforderlich und geeignet, können wir
                besonders schützenswerte Personendaten einfordern und
                bearbeiten. In diesem Fall unterliegen deren Bearbeitung
                strengerer Vertraulichkeit.
              </p>
            </div>

            <div className="h2-title">
              <h2>3.3 Was ist das Bearbeiten von Personendaten?</h2>
              <p>
                Bearbeiten ist jeder Umgang mit Personendaten, unabhängig von
                den angewandten Mitteln und Verfahren, insbesondere das
                Beschaffen, Speichern, Aufbewahren, Verwenden, Verändern,
                Bekanntgeben, Archivieren, Löschen oder Vernichten von Daten.
                3.4 Was ist das Bekanntgeben von Personendaten? Dies ist das
                Übermitteln oder Zugänglichmachen von Personendaten, bspw. die
                Veröffentlichung oder die Offenbarung an einen Dritten.
              </p>
            </div>

            <div className="h2-title">
              <h2>4. Wie können Sie mit uns in Kontakt treten?</h2>
              <p>
                Bei Fragen oder Anliegen zum Schutz Ihrer Daten durch uns
                erreichen Sie uns jederzeit per E-Mail unter info@pri-fs.ch.
                Verantwortlich für die Datenbearbeitungen, die über diese
                Website erfolgen, ist: PRI Facility Services GmbH Behar
                Jetullahu Werkstrasse 12 5080 Laufenburg
              </p>
            </div>

            <div className="h2-title">
              <h2>5. Bleibt unsere Datenschutzerklärung immer gleich?</h2>
              <p>
                Wir können diese Datenschutzerklärung jederzeit ändern. Die
                Änderungen werden auf www.pri-fs.ch veröffentlicht, Sie werden
                nicht gesondert informiert.
              </p>
            </div>

            <div className="h2-title">
              <h2>6. Allgemeine Grundsätze</h2>
              <p>
                In erster Linie bearbeiten wir Personendaten, die Sie uns
                übermitteln oder die wir beim Betrieb unserer Website sammeln.
                Unter Umständen erhalten wir Personendaten über Sie auch von
                Dritten. Das können folgende Kategorien sein: Personenstammdaten
                (Name, Adresse, Geburtsdaten, etc.); Kontaktdaten (Handynummer,
                E-Mailadresse, etc.); Finanzdaten (bspw. Kontoangaben);
                Onlinekennungen (bspw. Cookie-Kennung, IP-Adressen); Standort-
                und Verkehrsdaten; Ton- und Bildaufnahmen; besonders
                schützenswerte Daten (bspw. biometrische Daten oder Angaben über
                Ihre Gesundheit).{" "}
                <h3>
                  6.2 Unter welchen Voraussetzungen bearbeiten wir Ihre Daten?
                </h3>{" "}
                Wir bearbeiten Ihre Daten nach Treu und Glauben und nach den in
                dieser Datenschutzerklärung festgelegten Zwecken. Wir achten
                dabei auf eine transparente und verhältnismässige Bearbeitung.
                Sollten wir diese Grundsätze ausnahmsweise nicht einhalten
                können, kann die Datenbearbeitung trotzdem rechtmässig sein,
                weil ein Rechtfertigungsgrund vorliegt. Als Rechtfertigungsgrund
                kommt namentlich in Frage: Ihre Einwilligung; die Durchführung
                eines Vertrages oder vorvertraglicher Massnahmen; unsere
                berechtigten Interessen, sofern Ihre Interessen nicht
                überwiegen.{" "}
                <h3> 6.3 Wie können Sie Ihre Einwilligung widerrufen? </h3>{" "}
                Haben Sie uns eine Einwilligung zur Bearbeitung Ihrer
                personenbezogenen Daten für bestimmte Zwecke erteilt, bearbeiten
                wir Ihre Daten im Rahmen dieser Einwilligung, soweit wir keinen
                anderen Rechtfertigungsgrund haben Sie können Ihre erteilte
                Einwilligung jederzeit widerrufen und eine E-Mail an die im
                Impressum genannte Adresse schicken. Bereits erfolgte
                Datenverarbeitungen sind davon nicht betroffen.
                <h3>
                  6.4 In welchen Fällen können wir Ihre Daten an Dritte
                  weitergeben?{" "}
                </h3>{" "}
                a. Grundsatz Wir sind unter Umständen darauf angewiesen, die
                Dienste Dritter oder von verbundenen Unternehmen in Anspruch zu
                nehmen und diese mit der Verarbeitung Ihrer Daten zu beauftragen
                (sog. Auftragsverarbeiter). Kategorien der Empfänger sind
                namentlich: Buchhaltung, Treuhand und Revisionsunternehmen;
                Beratungsunternehmen (Rechtsberatung, Steuern, etc.);
                IT-Dienstleister (Webhosting, Support, Clouddienste,
                Webseitengestaltung, etc.); Zahlungsdienstleister; Anbieter von
                Tracking-, Conversion- und Werbedienstleistungen. Wir stellen
                sicher, dass diese Dritten und unsere verbundenen Unternehmen
                die Voraussetzungen des Datenschutzes einhalten und Ihre
                personenbezogenen Daten vertraulich behandeln. Unter Umständen
                sind wir auch verpflichtet, Ihre personenbezogenen Daten an
                Behörden bekanntzugeben. b. Weitergabe an Partner und
                Kooperationsunternehmen Wir arbeiten teils mit unterschiedlichen
                Unternehmen und Partnern zusammen, die Ihre Angebote auf unserer
                Website aufschalten. Es ist für Sie erkennbar, dass es sich um
                ein Drittangebot (als «Werbung» gekennzeichnet) handelt. Wenn
                Sie ein solches Angebot in Anspruch nehmen, übermitteln wir Ihre
                personenbezogenen Daten an den entsprechenden Partner oder das
                Kooperationsunternehmen (z.B Name, Funktion, Kommunikation
                etc.), dessen Angebot Sie wahrnehmen wollen Diese Partner und
                Kooperationsunternehmen sind eigenständig für die empfangenen
                personenbezogenen Daten verantwortlich. Nach der Übermittlung
                der Daten gelten die Datenschutzbestimmungen des jeweiligen
                Partners. c. Besuch unserer Social-Media-Kanäle Wir haben auf
                unserer Website Links zu unseren Social-Media-Kanälen
                eingebettet. Das ist für Sie jeweils ersichtlich (typischerweise
                über entsprechende Symbole). Klicken Sie auf die Symbole, werden
                Sie auf unsere Social-Media-Kanäle weitergeleitet. Die Social
                Media Anbieter erfahren in diesem Fall, dass Sie von unserer
                Website auf deren Plattform zugreifen. Die Social Media Anbieter
                können die so erhobenen Daten für eigene Zwecke nutzen. Wir
                weisen darauf hin, dass wir keine Kenntnis vom Inhalt der
                übermittelten Daten sowie deren Nutzung durch die Betreiber
                erhalten. d. Weitergabe ins Ausland Unter Umständen kann es im
                Rahmen der Auftragsbearbeitung zu Übermittlung Ihrer
                personenbezogenen Daten an Unternehmen im Ausland kommen. Diese
                Unternehmen sind im gleichen Umfang zum Datenschutz
                verpflichtet, wie wir selbst. Die Übermittlung kann weltweit
                stattfinden. Entspricht das Datenschutzniveau nicht demjenigen
                des der Schweiz, so nehmen wir eine vorgängige
                Risikoeinschätzung vor und stellen vertraglich sicher, dass der
                gleiche Schutz wie in der Schweiz garantiert wird (bspw. mittels
                der neuen Standardvertragsklauseln der EU-Kommission oder
                anderen, gesetzlich vorgegebenen Massnahmen). Sollte unsere
                Risikoeinschätzung negativ ausfallen, ergreifen wir zusätzliche
                technische Massnahmen zum Schutz Ihrer Daten. Sie können die
                Standardvertragsklauseln der EU-Kommission abrufen unter
                folgendem Link.{" "}
                <h3>6.5 Wie lange bewahren wir Ihre Daten auf?</h3> Wir
                speichern personenbezogene Daten nur so lange, wie dies
                erforderlich ist, um die einzelnen Zwecke, zu denen die Daten
                erhoben wurden, zu erfüllen. Daten, die wir bei Ihrem Besuch auf
                unserer Website speichern, werden während zwölf Monaten
                aufbewahrt. Eine Ausnahme gilt für Analyse- und Trackingdaten,
                die länger aufbewahrt werden können. Vertragsdaten speichern wir
                länger, da wir dazu durch gesetzliche Vorschriften verpflichtet
                sind. Wir müssen insbesondere geschäftliche Kommunikation,
                geschlossene Verträge und Buchungsbelege bis zu 10 Jahren
                aufbewahren. Soweit wir solche Daten von Ihnen nicht mehr zur
                Durchführung der Dienstleistungen benötigen, werden die Daten
                gesperrt und wir verwenden sie nur noch für Zwecke der
                Rechnungslegung und für Steuerzwecke.{" "}
                <h3>6.6 Wie schützen wir Ihre Daten? </h3>Wir werden Ihre Daten
                sicher aufbewahren und alle angemessenen Massnahmen ergreifen,
                um Ihre Daten vor Verlust, Zugriff, Missbrauch oder Änderungen
                zu schützen. Unsere Vertragspartner und Mitarbeitende, die
                Zugang zu Ihren Daten haben, sind zur Einhaltung der
                datenschutzrechtlichen Bestimmungen verpflichtet. In manchen
                Fällen wird es erforderlich sein, dass wir Ihre Anfragen an mit
                uns verbundene Unternehmen weiterreichen. Auch in diesen Fällen
                werden Ihre Daten vertraulich behandelt. Innerhalb unserer
                Webseite verwenden wir das SSL-Verfahren (Secure Socket Layer)
                in Verbindung mit der jeweils höchsten Verschlüsselungsstufe,
                die von Ihrem Browser unterstützt wird.{" "}
                <h3>6.8 Welche Rechte haben Sie? </h3>
                a. Auskunftsrecht Sie können jederzeit eine Auskunft über die
                von uns über Sie gespeicherten Daten verlangen. Wir bitten Sie,
                Ihr Auskunftsgesuch zusammen mit einem Identitätsnachweis an
                info@pri-fs.ch zu senden. Sie haben ausserdem das Recht, Ihre
                Daten in einem gängigen Dateiformat zu erhalten, wenn wir Ihre
                Daten automatisiert bearbeiten, und wenn Sie Ihre Einwilligung
                für die Bearbeitung dieser Daten erteilt haben; oder Sie Daten
                im Zusammenhang mit dem Abschluss oder der Abwicklung eines
                Vertrags bekannt gegeben haben. Wir können die Auskunft bzw.
                Datenherausgabe einschränken oder verweigern, wenn dies unseren
                gesetzlichen Verpflichtungen, berechtigten eigenen oder
                öffentlichen Interessen oder Interessen einer Drittperson
                entgegensteht. Die Bearbeitung Ihres Gesuchs unterliegt der
                gesetzlichen Bearbeitungsfrist von 30 Tagen. Diese Frist dürfen
                wir jedoch aufgrund von hohem Anfragevolumen, aus rechtlichen
                oder technischen Gründen oder weil wir nähere Angaben von Ihnen
                benötigen, verlängern. Sie werden über die Fristverlängerung
                rechtzeitig mindestens in Textform informiert. b. Löschung und
                Berichtigung Sie haben jederzeit die Möglichkeit, die Löschung
                oder Berichtigung Ihrer Daten zu verlangen. Wir können das
                Gesuch abweisen, wenn gesetzliche Vorschriften uns zur längeren
                bzw. unveränderten Aufbewahrung verpflichten oder ein
                Erlaubnistatbestand Ihrem Gesuch entgegensteht. Bitte beachten
                Sie, dass die Ausübung Ihrer Rechte unter Umständen im Konflikt
                mit vertraglichen Abmachungen stehen und entsprechende
                Auswirkungen auf die Vertragsdurchführung haben kann (z.B.
                vorzeitige Vertragsauflösung oder Kostenfolgen). c. Rechtsweg
                Sind Sie von der Bearbeitung personenbezogener Daten betroffen,
                haben Sie das Recht, Ihre Rechte gerichtlich durchzusetzen oder
                bei der zuständigen Aufsichtsbehörde eine Meldung einzureichen.
                Die zuständige Aufsichtsbehörde in der Schweiz ist der
                Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte:
                https://www.edoeb.admin.ch.
              </p>
            </div>

            <div className="h2-title">
              <h2>7. Einzelne Datenverarbeitungsvorgänge</h2>
            </div>

            <div className="h2-title">
              <h3>
                7.1 Bereitstellen der Webseite und Erstellung von Logfiles
              </h3>
              <p>
                Welche Informationen erhalten wir und wie nutzen wir sie? Indem
                Sie www.pri-fs.ch besuchen, werden auf unseren Servern oder auf
                Servern von Dienstleistungen und Produkten, die wir beziehen und
                / oder installiert haben, bestimmte Daten automatisch zu Zwecken
                der Systemadministration, für statistische oder für
                Sicherungszwecke oder für Trackingzwecke gespeichert. Es handelt
                sich dabei um: den Namen Ihres Internetserviceproviders; Ihre
                IP-Adresse (unter Umständen); die Version Ihrer
                Browser-Software; das Betriebssystem des Rechners mit dem auf
                die URL zugegriffen wird; das Datum und die Uhrzeit des
                Zugriffs; die Webseite, von der aus Sie URL besuchen; die
                Suchwörter die Sie benutzt haben, um URL finden. Weshalb dürfen
                wir diese Daten bearbeiten? Diese Daten können keiner bestimmten
                Person zugeordnet werden und es findet keine Zusammenführung
                dieser Daten mit anderen Datenquellen statt. Die Speicherung der
                Logfiles erfolgt, um die Funktionsfähigkeit der Webseite zu
                garantieren und zur Sicherstellung der Sicherheit unserer
                informationstechnischen Systeme. Hierin besteht unser
                berechtigtes Interesse. Wie können Sie die Datenerfassung
                verhindern? Die Daten werden nur solange gespeichert, wie dies
                zur Erreichung des Zweckes ihrer Erhebung notwendig ist.
                Dementsprechend werden die Daten nach Beendigung jeder Sitzung
                gelöscht. Die Speicherung der Logfiles ist für den Betrieb der
                Webseite zwingend notwendig, Sie haben daher keine Möglichkeit
                dagegen Widerspruch zu erheben. <h3>7.2 Zählpixel Wie funktionieren
                Zählpixel? </h3>Wir können Zählpixel auf unserer Website verwenden.
                Zählpixel werden auch als Web-Beacons bezeichnet. Bei Zählpixeln
                – auch von Dritten, deren Dienste wir nutzen – handelt es sich
                um kleine, üblicherweise nicht sichtbare Bilder, die beim Besuch
                unserer Website automatisch abgerufen werden. Mit Zählpixeln
                können die gleichen Angaben wie in Server-Logdateien erfasst
                werden. Wir nutzen sie zu denselben Zwecken wie Logfiles – Sie
                können die Datenerfassung nicht verhindern.<h3> 7.3 Auswertungen</h3> Zum
                Teil werden Ihre personenbezogenen Daten automatisiert
                verarbeitet, um bestimmte persönliche Aspekte zu bewerten.
                Solche Bewertungen werden von uns insbesondere eingesetzt, um
                Sie zielgerichtet über gewisse Dienstleistungen oder Produkte
                von uns zu informieren und beraten zu können. Zu diesem Zweck
                setzen wir Auswertungsinstrumente ein, dank denen wir
                bedarfsgerecht kommunizieren können und passende
                Werbungsmassnahmen inkl. Markt- und Meinungsforschung ergreifen
                können.<h3> 7.4 Kontakt </h3> E-Mail Welche Informationen erhalten wir und
                wie nutzen wir sie? Sie haben die Möglichkeit uns per E-Mail zu
                kontaktieren. Nehmen Sie mit uns Kontakt per E-Mail auf, werden
                folgende Daten verarbeitet: E-Mail-Adresse; Inhalt, Betreff und
                Datum Ihrer E-Mail; von Ihnen angegebene Kontaktdaten (z.B.
                Name, ggf. Telefonnummer, Anschrift). Weshalb dürfen wir diese
                Daten bearbeiten? Ihre Angaben werden zwecks Bearbeitung der
                Anfrage und für den Fall von Anschlussfragen bei uns
                gespeichert. Als Rechtfertigungsgrund dienen vorvertragliche
                Massnahmen bzw. unsere berechtigten Interessen an der Erledigung
                der Anfrage. Sicherheitshinweis Wir weisen Sie darauf hin, dass
                E-Mails auf dem Übertragungsweg unbefugt und unbemerkt
                mitgelesen oder verändert werden können. Durch den Spam-Filter
                können E-Mails abgewiesen werden, wenn diese durch bestimmte
                Merkmale als Spam identifiziert wurden. <h3>7.5 Erfolgs- und
                Reichweitenmessung</h3> Wie funktioniert die Erfolgs- und
                Reichweitenmessung? Unsere Benachrichtigungen und Mitteilungen
                können Weblinks oder Zählpixel enthalten, die erfassen, ob eine
                einzelne Mitteilung geöffnet wurde und welche Weblinks dabei
                angeklickt wurden. Solche Weblinks und Zählpixel können die
                Nutzung von Benachrichtigungen und Mitteilungen auch
                personenbezogen erfassen. Weshalb dürfen wir diese Daten
                bearbeiten? Wir benötigen diese statistische Erfassung der
                Nutzung für die Erfolgs- und Reichweitenmessung, um
                Benachrichtigungen und Mitteilungen aufgrund der Bedürfnisse und
                Lesegewohnheiten der Empfängerinnen und Empfänger effektiv und
                nutzerfreundlich sowie dauerhaft, sicher und zuverlässig
                anbieten zu können. <h3>7.6 Cookies Wie funktionieren Cookies? </h3>
                Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich
                um Datensätze, die mit Hilfe des Browsers auf dem Betriebssystem
                Ihres Gerätes abgelegt werden, wenn Sie unsere Webseite
                aufrufen. Cookies richten auf Ihrem Rechner keinen Schaden an
                und enthalten keine Viren. Welche Informationen erhalten wir und
                wie nutzen wir sie? Die meisten der von uns verwendeten Cookies
                sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres
                Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem
                Endgerät gespeichert bis Sie diese löschen. Diese Cookies
                ermöglichen es uns, Ihren Browser beim nächsten Besuch
                wiederzuerkennen. Dadurch können wir gewisse Einstellungen (wie
                z.B. Spracheinstellungen oder Ortsangaben) speichern, damit Sie
                diese bei erneutem Aufruf der Webseite nicht neu eingeben
                müssen. Weshalb dürfen wir Cookies verwenden? Wir verwenden
                Cookies, damit wir unsere Webseite benutzerfreundlicher,
                effektiver und sicherer gestalten können. Die Verwendung von
                Cookies und die diesbezügliche Verarbeitung Ihrer Daten erfolgt
                aufgrund unserer berechtigten Interessen an den genannten
                Zwecken Wie können Sie die Datenerfassung via Cookies
                verhindern? Die Cookies werden auf Ihrem Rechner gespeichert.
                Sie haben daher die volle Kontrolle über die Verwendung der
                Cookies. Sie können diese vollständig löschen oder durch
                Änderung der Einstellungen in Ihrem Browser die Übertragung
                deaktivieren oder einschränken. Bei Deaktivierung von Cookies
                für unsere Webseite können möglicherweise nicht mehr alle
                Funktionen der Webseite vollumfänglich genutzt werden.<h3> 7.7
                Google Web Fonts </h3> Wie funktioniert Google Web Fonts? Wir benutzen
                auf unserer Website zur einheitlichen Darstellung von
                Schriftarten so genannte Web Fonts, die von Google
                bereitgestellt werden. Beim Aufruf einer unserer Seiten lädt Ihr
                Browser die benötigten Web Fonts in Ihren Browsercache, um Texte
                und Schriftarten korrekt anzuzeigen. Welche Informationen teilen
                Sie mit uns und wie werden sie genutzt? Der von Ihnen verwendete
                Browser stellt eine Verbindung zu den Servern von Google her.
                Hierdurch erlangt Google Kenntnis darüber, dass via Ihre
                IP-Adresse unsere Website aufgerufen wurde. Wenn Ihr Browser Web
                Fonts nicht unterstützt, wird eine Standardschrift von Ihrem
                Computer genutzt. Weshalb dürfen wir Google Web Fonts verwenden?
                Die Nutzung von Google Web Fonts erfolgt im Interesse einer
                einheitlichen und ansprechenden Darstellung unserer
                Online-Angebote. Für weitere Informationen zu Google Web Fonts
                siehe die FAQs von Google Web Fonts:
                https://developers.google.com/fonts/faq. <h3>7.8 reCAPTCHA </h3> ReCAPTCHA
                wird verwendet, um automatische Programme daran zu hindern, auf
                einer Webseite zu agieren. Es ist ein Verfahren, das es Menschen
                ermöglicht, einige einfache Aufgaben auszuführen, die für
                Computer schwierig zu lösen sind. Diese Aufgaben können darin
                bestehen, ein Bild zu identifizieren, bestimmte Buchstaben
                auszuwählen oder einen bestimmten Satz zu lesen und einzugeben.
                Mit reCAPTCHA können Webseitenbesitzer sicherstellen, dass
                Benutzer tatsächlich Menschen sind, die auf Ihrer Seite
                interagieren, und nicht automatisierte Bots.<h3> 7.9 PrivacyBee </h3>
                PrivacyBee wird auf Webseiten verwendet, um alle
                datenschutzrelevante Dienste zu erkennen und daraus eine
                individuelle Datenschutzerklärung zu erzeugen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};


const slideInFromLeft = keyframes`
  from {
    opacity: 0;
    left: -100px;
  }

  to {
    opacity: 1;
    left: 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em 4%;
  margin-top: 50px;
  .first-div {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 25px;
    width: 75%;
  }
  .title-data {
    align-items: center;
    text-align: center;
    font-weight: 600;
    background: var(--gradientBackground2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative; 
    animation: ${slideInFromLeft} 1.5s ease-out forwards;
  }
  .second-part {
    display: flex;
    justify-content: space-between;
  }
  .h2-titles {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
    animation: ${slideInFromLeft} 1s ease-out forwards;
  }
  .h2-title {
    margin-bottom: 50px;
  }
`;

export default DataProtection;
